import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

const CustomCardForm = () => {                                                                                           
  return (
    <div>
      <div style={{ padding: "1rem 0.75rem 0.375rem 0" }}>
        <CardNumberElement
          id="card-number-element"
          options={{
            style: {
              base: {
                fontSize: "18px",
                fontWeight: "700",
                color: "#000000",
                padding: "1rem 0.75rem 0.375rem 0",
                letterSpacing: "0.025em",
                fontFamily: "sans-serif, monospace",
                "::placeholder": { color: "#000000" },
              },
              invalid: { color: "#9e2146" },
            },
            placeholder: "Card Number",
          }}
        />
        <hr style={{ borderTop: "1px solid white" }} />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flex: "1", marginRight: "1rem" }}>
          <CardExpiryElement
            options={{
              style: {
                base: {
                  fontSize: "18px",
                  fontWeight: "700",
                  color: "#000000",
                  letterSpacing: "0.025em",
                  fontFamily: "sans-serif, monospace",
                  "::placeholder": { color: "#000000" },
                },
                invalid: { color: "#9e2146" },
              },
            }}
          />
          <hr style={{ borderTop: "1px solid white" }} />
        </div>

        <div  style={{ flex: "1" }}>
          <CardCvcElement
            options={{
              style: {
                base: {
                  fontSize: "18px",
                  fontWeight: "700",
                  color: "#000000",
                  letterSpacing: "0.025em",
                  fontFamily: "sans-serif, monospace",
                  "::placeholder": { color: "#000000" },
                },
                invalid: { color: "#9e2146" },
              },
            }}
          />
          <hr style={{ borderTop: "1px solid white" }} />
        </div>
      </div>
    </div>
  );
};

export default CustomCardForm;
