import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import moment from "moment";
import axios from "axios";
import Visa from "../../assets/images/visa-card.svg";
import MasterCard from "../../assets/images/mastercard.svg";
import Amex from "../../assets/images/amex.svg";
import { patronRegistrationValidation } from "../Validation/ValidationSchema";
import { mainServerAppUrl } from "../../apis/mainApi";
// import { SideMenu } from "../Side-Menu/sideMenu";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { CountryCodes } from "../../common/countryCodes";
import { ScrollToFieldError } from "../Validation/scrollToFieldError";
import MaskedInput from "react-text-mask";
import { cardNumberMask } from "../Validation/cardNumberMask";
import jsPDF from "jspdf";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";
import { getAuthUser } from "../Authentication/authHelper";
import { useStripe, useElements } from "@stripe/react-stripe-js";
// import { CardNumberElement } from "@stripe/react-stripe-js";
import CustomCardForm from "../cardElement";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

export default function RegistrationForm() {
  const [membershipNo, setMembershipNo] = useState("");
  const [membershipDiv, setMembershipDiv] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showLink, setShowLink] = useState(true);
  const [previewImage, setPreviewImage] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("Role", "Patron");
  }, []);

  const navigate = useNavigate();

  const handleImageUpload = (e, setFieldValue, values) => {
    const file = e.target.files[0];
    // console.log("file................",file)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setFieldValue("image", reader.result);
        // console.log("reader.result----->>>>",reader.result)
      };
      reader.readAsDataURL(file);
    }
  };

  const getMembershipNo = (email) => {
    axios
      .get(`${mainServerAppUrl}/user/${email}`)
      .then((data) => {
        setMembershipNo(data?.data?.details[0]?.membership_no);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("membershipNo==>", membershipNo);
  const saveAsPDF = (data, membershipNumber) => {
    const pdf = new jsPDF();

    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 0);

    let x = 10;
    let y = 10;

    pdf.text("Membership Number" + ": " + membershipNumber, x, y);

    y += 10;

    Object.keys(data).forEach((field, index) => {
      if (field === "role") {
        return; // Skip the "Role" field
      }
      const value = data[field];

      // Format the field name and value
      const formattedField = field
        .replace(/_/g, " ")
        .replace(/^\w/, (c) => c.toUpperCase());
      const formattedValue = formatValue(value);

      pdf.text(formattedField + ": " + formattedValue, x, y);

      y += 10;
    });

    pdf.save("Homey-FOODS Patron Registration Details.pdf");
  };

  // Helper function to format the value
  const formatValue = (value) => {
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return value.map((item) => item.toString()).join(", ");
      } else {
        let formattedObject = "";
        for (const prop in value) {
          if (value.hasOwnProperty(prop)) {
            const formattedProp = prop
              .replace(/_/g, " ")
              .replace(/^\w/, (c) => c.toUpperCase());
            const formattedPair = `${formattedProp}: ${value[prop]}\n`;
            formattedObject += formattedPair;
          }
        }
        return formattedObject;
      }
    } else {
      return value.toString();
    }
  };

  const loginUser = (data) => {
    axios
      .post(mainServerAppUrl + "/user/login", {
        email: data?.email,
        password: data?.password,
      })
      .then((result) => {
        localStorage.setItem("_token", result?.data?.token);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateProfile = (value) => {
    if (!value) {
      return "Profile is required";
    } else if (value.length < 40) {
      return "Profile must be at least 40 characters";
    } else if (value.length > 200) {
      return "Profile must not exceed 200 characters";
    }
    return undefined; // No error
  };

  return (
    <div className="max-resolution">
      <div className="home-section-1 position-relative">
        <span className="text_msg">
          {membershipNo
            ? "PATRON REGISTRATION SUCCESSFUL"
            : "PATRON REGISTRATION INFORMATION"}
        </span>
        {membershipDiv && (
          <span className="text_msg">
            You have successfully registered. Your membership number is -
            {membershipNo}.
          </span>
        )}
      </div>
      <Header />
      <main>
        <div className="home-section-1 position-relative home-section-2">
          {/* {membershipNo && <SideMenu />} */}

          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        <div className="custom-form centered pb-4">
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={patronRegistrationValidation}
            initialValues={{
              registration_date: moment(new Date()).format("YYYY-MM-DD"),
              profile: "",
              first_name: "",
              last_name: "",
              dob: {
                day: "",
                month: "",
                year: "",
              },
              email: "",
              address_line1: "",
              address_line2: "",
              city: "",
              password: "",
              phone: "",
              country: "",
              state: "",
              zip_code: "",
              country_code: "93",
              preferences: [],
              allergies: [],
              stripe_card_token_id: "",
              card_details: {
                card_type: "",
                // card_number: "",
                // card_expiry: "",
                // cvv: "",
              },
              role: "Patron",
              photo: null,
            }}
            // onSubmit={async (values, { setFieldError, setFieldValue }) => {
            //   try {
            //     const cardElement = elements.getElement(CardNumberElement);

            //     // Create Payment Method with Stripe
            //     const { paymentMethod, error } =
            //       await stripe.createPaymentMethod({
            //         type: "card",
            //         card: cardElement,
            //         billing_details: {
            //           email: values?.email, // Send any necessary billing details
            //         },
            //       });

            //     console.log("paymentMethod==>>", paymentMethod);

            //     if (error) {
            //       // Set error in Formik if card details are invalid
            //       setFieldError(
            //         "stripe_card_token_id",
            //         "Card details are not valid"
            //       );
            //       toast.error("Card details are not valid", {
            //         id: "patronFailure",
            //         duration: 3000,
            //       });
            //       return; // Stop submission on error
            //     }

            //     if (paymentMethod) {
            //       // Set the payment method ID in the form value
            //       await setFieldValue("stripe_card_token_id", paymentMethod.id);

            //       // Proceed to submit the form after setting stripe_card_token_id
            //       const response = await axios.post(
            //         mainServerAppUrl + "/user/register",
            //         values
            //       );

            //       console.log("response", response);
            //       if (response.status === 201) {
            //         setIsSubmitted(true);
            //         setMembershipDiv(true);
            //         setTimeout(() => {
            //           window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            //           getMembershipNo(response?.data?.email);
            //           loginUser(values);
            //           toast.success("Patron Registration Successful", {
            //             id: "patronSuccess",
            //             duration: 3000,
            //           });
            //         }, 2000);
            //       }
            //       setTimeout(() => {
            //         navigate("/patron-code-of-Conduct"); // Navigate to the information page after registration
            //       }, 6000);
            //     }
            //   } catch (error) {
            //     console.error("Registration error:", error);
            //     toast.error(error?.response?.data?.message, {
            //       id: "patronFailure",
            //       duration: 3000,
            //     });
            //   }
            // }}

            onSubmit={async (values, { setFieldError, setFieldValue }) => {
              try {
                const cardElement = elements.getElement(CardNumberElement);
                if (!cardElement) {
                  setFieldError(
                    "stripe_card_token_id",
                    "Card element not found"
                  );
                  return;
                }

                // Create Payment Method with Stripe
                const { paymentMethod, error } =
                  await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                    billing_details: {
                      email: values?.email, // Send any necessary billing details
                    },
                  });

                if (error) {
                  setFieldError(
                    "stripe_card_token_id",
                    "Card details are not valid"
                  );
                  toast.error("Card details are not valid", {
                    id: "patronFailure",
                    duration: 3000,
                  });
                  return; // Stop submission on error
                }

                if (paymentMethod?.id) {
                  // Set the payment method ID in the form value
                  await setFieldValue("stripe_card_token_id", paymentMethod.id);

                  // Now submit the form with updated values after setting the token
                  const updatedValues = {
                    ...values,
                    stripe_card_token_id: paymentMethod.id, // Ensure the updated token is sent
                  };

                  // Proceed with your form submission logic
                  const response = await axios.post(
                    mainServerAppUrl + "/user/register",
                    updatedValues // Formik values with updated stripe_card_token_id
                  );

                  if (response.status === 201) {
                    setIsSubmitted(true);
                    setMembershipDiv(true);
                    setTimeout(() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                      getMembershipNo(response?.data?.email);
                      loginUser(updatedValues);
                      toast.success("Patron Registration Successful", {
                        id: "patronSuccess",
                        duration: 3000,
                      });
                    }, 2000);
                  }
                  setTimeout(() => {
                    navigate("/patron-code-of-Conduct"); // Navigate to the information page after registration
                  }, 6000);
                } else {
                  console.error("Payment method ID not found");
                }
              } catch (error) {
                console.error("Registration error:", error);
                toast.error(error?.response?.data?.message, {
                  id: "patronFailure",
                  duration: 3000,
                });
              }
            }}
          >
            {({
              errors,
              status,
              touched,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (
              <fieldset disabled={isSubmitted}>
                <Form>
                  <div className="row">
                    <div className="col-12">
                      <Field
                        type="date"
                        name="registration_date"
                        placeholder="Date of Registration"
                        className={
                          "w-100 form-control" +
                          (errors.city && touched.city ? " is-invalid" : "")
                        }
                        disabled
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        className={
                          "w-100 form-control" +
                          (errors.first_name && touched.first_name
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        className={
                          "w-100 form-control" +
                          (errors.last_name && touched.last_name
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-12">
                      <br />
                      <b style={{ fontSize: "18px" }}>Date of birth</b>
                    </div>
                    <div className="col-4">
                      <Field
                        type="number"
                        name="dob.day"
                        placeholder="Day"
                        className={
                          "w-100 form-control" +
                          (errors.dob?.day && touched.dob?.day
                            ? " is-invalid"
                            : "")
                        }
                        value={values.dob?.day}
                      />
                      <ErrorMessage
                        name="dob.day"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-4">
                      <Field
                        type="number"
                        name="dob.month"
                        placeholder="Month"
                        className={
                          "w-100 form-control" +
                          (errors.dob?.month && touched.dob?.month
                            ? " is-invalid"
                            : "")
                        }
                        value={values.dob?.month}
                      />
                      <ErrorMessage
                        name="dob.month"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-4">
                      <Field
                        type="number"
                        name="dob.year"
                        placeholder="Year"
                        className={
                          "w-100 form-control" +
                          (errors.dob?.year && touched.dob?.year
                            ? " is-invalid"
                            : "")
                        }
                        value={values.dob?.year}
                      />
                      <ErrorMessage
                        name="dob.year"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="address_line1"
                        placeholder="Address line 1"
                        className={
                          "w-100 form-control" +
                          (errors.address_line1 && touched.address_line1
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="address_line1"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="address_line2"
                        placeholder="Address line 2"
                        className={
                          "w-100 form-control" +
                          (errors.address_line2 && touched.address_line2
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="address_line2"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="city"
                        placeholder="City"
                        className={
                          "w-100 form-control" +
                          (errors.city && touched.city ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-12">
                      <div className="select-wrapper">
                        <CountryDropdown
                          name="country"
                          value={values.country}
                          onChange={(_, e) => handleChange(e)}
                          onBlur={handleBlur}
                          className={
                            "w-100 form-control" +
                            (errors.country && touched.country
                              ? " is-invalid"
                              : "")
                          }
                          defaultOptionLabel="Country"
                        />
                        <span className="select_arrow"></span>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="form_invalid"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="select-wrapper">
                        <RegionDropdown
                          name="state"
                          country={values.country ? values.country : "State"}
                          value={values.country ? values.state : "State"}
                          onChange={(_, e) => handleChange(e)}
                          onBlur={handleBlur}
                          className={
                            "w-100 form-control" +
                            (errors.state && touched.state ? " is-invalid" : "")
                          }
                          defaultOptionLabel="State"
                        />
                        <span className="select_arrow"></span>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="form_invalid"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="zip_code"
                        placeholder="Zip Code"
                        className={
                          "w-100 form-control" +
                          (errors.zip_code && touched.zip_code
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="zip_code"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-4">
                          <div className="select-wrapper">
                            <Field
                              as="select"
                              name="country_code"
                              className="w-100 form-control"
                            >
                              {CountryCodes.map((item) => (
                                <option key={item.iso} value={item.code}>
                                  +{item.code}
                                </option>
                              ))}
                            </Field>
                            <span className="select_arrow"></span>
                          </div>
                        </div>
                        <div className="col-8">
                          <Field
                            type="number"
                            name="phone"
                            placeholder="Phone"
                            className={
                              "w-100 form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="form_invalid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="email"
                        placeholder="Email"
                        className={
                          "w-100 form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className={
                          "w-100 form-control" +
                          (errors.password && touched.password
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <div
                        className="p-viewer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={
                            showPassword
                              ? "fa-solid fa-eye fa-lg"
                              : "fa-sharp fa-solid fa-eye-slash fa-lg"
                          }
                        ></i>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    {/* <div className="col-12">
                      <Field
                        type="text"
                        name="profile"
                        placeholder="Profile"
                        className={
                          "w-100 form-control" +
                          (errors.profile && touched.profile
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="profile"
                        component="div"
                        className="form_invalid"
                      />
                    </div> */}
                    <div className="col-12">
                      <Field name="photo">
                        {({ field }) => (
                          <div>
                            <br />
                            <b style={{ fontSize: "18px" }}>
                              Please upload your recent photo
                            </b>
                            <input
                              {...field}
                              type="file"
                              onChange={(e) => {
                                handleImageUpload(e, setFieldValue, values);
                                field.onChange(e); // Formik's onChange
                              }}
                              onBlur={handleBlur}
                              accept="image/*"
                              className={
                                "w-100 form-control" +
                                (errors.photo && touched.photo
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            {previewImage && (
                              <div className="image-preview pt-2">
                                <img
                                  src={previewImage}
                                  alt="Image Preview"
                                  style={{
                                    maxWidth: "40%",
                                    height: "auto",
                                    margin: "auto",
                                  }}
                                />
                              </div>
                            )}
                            <ErrorMessage
                              name="photo"
                              component="div"
                              className="form_invalid"
                            />
                          </div>
                        )}
                      </Field>
                    </div>

                    <div className="col-12">
                      <Field name="profile" validate={validateProfile}>
                        {({ field }) => (
                          <div>
                            <textarea
                              {...field}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              e
                              placeholder="Profile: About yourself in 40 words or less"
                              className={
                                "w-100 form-control" +
                                (errors.profile && touched.profile
                                  ? " is-invalid"
                                  : "")
                              }
                              value={
                                membershipNo
                                  ? getAuthUser()?.profile
                                  : field.value
                              }
                              maxLength={200}
                            ></textarea>
                            <ErrorMessage
                              name="profile"
                              component="div"
                              className="form_invalid"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-12 card_section">
                      <span className="form_heading">Card Type</span>
                      <div className="d-flex align-items-center">
                        <div className="col-3 p-0">
                          <button
                            className="visa_card"
                            onClick={(e) => {
                              e.preventDefault();
                              setFieldValue("card_details.card_type", "Visa");
                            }}
                          >
                            <img src={Visa} />
                          </button>
                        </div>
                        <div className="col-3 p-0">
                          <button
                            className="master_card"
                            onClick={(e) => {
                              e.preventDefault();
                              setFieldValue(
                                "card_details.card_type",
                                "Master Card"
                              );
                            }}
                          >
                            <img src={MasterCard} />
                          </button>
                        </div>
                        <div className="col-3 p-0">
                          <button
                            className="american_express"
                            onClick={(e) => {
                              e.preventDefault();
                              setFieldValue(
                                "card_details.card_type",
                                "American Express"
                              );
                            }}
                          >
                            <img src={Amex} />
                          </button>
                        </div>
                        <div className="col-3 p-0">
                          <span
                            className="form_heading p-0"
                            onClick={() => {
                              setFieldValue("card_details.card_type", "");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Others
                          </span>
                        </div>
                      </div>
                      <input
                        type="text"
                        name="card_details.card_type"
                        value={values.card_details.card_type}
                        onChange={handleChange}
                        placeholder="Card Type"
                        className="w-100 form-control"
                        disabled={
                          values.card_details.card_type === "Visa" ||
                          values.card_details.card_type ===
                            "American Express" ||
                          values.card_details.card_type === "Master Card"
                        }
                      />
                      <ErrorMessage
                        name="card_details.card_type"
                        component="div"
                        className="form_invalid"
                      />
                      {/* -----------------Render the CustomCardForm */}
                      <CustomCardForm />

                      <div className="col-12 notes_area">
                        <p>
                          Note : Your credit card will not be charged for
                          registration. Only on your requesting for Meals
                        </p>
                      </div>
                    </div>
                    <div className="row checkbox_group">
                      <span className="form_heading">Preference</span>
                      <div className="col-12 p-0">
                        <div className="d-flex flex-wrap">
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="preference_1"
                              name="preferences"
                              value="Veg"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preference_1"
                            >
                              Veg
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="preference_2"
                              name="preferences"
                              value="Non-Veg"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preference_2"
                            >
                              Non-Veg
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="preference_3"
                              name="preferences"
                              value="Vegan"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preference_3"
                            >
                              Vegan
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="preference_4"
                              name="preferences"
                              value="Halal"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preference_4"
                            >
                              Halal
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="preference_5"
                              name="preferences"
                              value="Kosher"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preference_5"
                            >
                              Kosher
                            </label>
                            <ErrorMessage
                              name="preferences"
                              component="div"
                              className="form_invalid"
                            />
                          </div>
                        </div>
                      </div>

                      <span className="form_heading">Allergies</span>
                      <div className="col-12 p-0">
                        <div className="d-flex flex-wrap">
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_1"
                              name="allergies"
                              value="Eggs"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_1"
                            >
                              Eggs
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_2"
                              name="allergies"
                              value="Peanuts"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_2"
                            >
                              Peanuts
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_3"
                              name="allergies"
                              value="Shell Fish"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_3"
                            >
                              Shell Fish
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_4"
                              name="allergies"
                              value="Soy"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_4"
                            >
                              Soy
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_5"
                              name="allergies"
                              value="Gluten"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_5"
                            >
                              Gluten
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_6"
                              name="allergies"
                              value="None"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_6"
                            >
                              None
                            </label>
                          </div>
                          <ErrorMessage
                            name="allergies"
                            component="div"
                            className="form_invalid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    <hr />
                    <div style={{ textAlign: "center", fontSize: "15px" }}>
                      <p>
                        <b>Google Navigation downloaded on mobile phone?</b>
                      </p>
                      <label>
                        <b>Yes</b>
                        <input
                          type="radio"
                          name="google"
                          onChange={() => setShowLink(false)}
                        />
                      </label>
                      <label>
                        <b>No</b>
                        <input
                          type="radio"
                          name="google"
                          defaultChecked
                          onChange={() => setShowLink(true)}
                        />
                      </label>
                      {showLink && (
                        <div>
                          <p>
                            You can download Google Navigation from{" "}
                            <a
                              target="_blank"
                              href="https://play.google.com/store/apps/details?id=com.google.android.apps.maps&hl=en-IN"
                            >
                              here
                            </a>
                            .
                          </p>
                        </div>
                      )}
                      <hr />
                    </div>
                    <button
                      type="submit"
                      value="REGISTER"
                      className="btn w-100"
                    >
                      REGISTER
                    </button>
                  </div>
                  <ScrollToFieldError />
                </Form>
              </fieldset>
            )}
          </Formik>
        </div>
        <div
          className="alreay-account-section centered"
          style={{ paddingBottom: "2rem" }}
        >
          <b>
            <p>
              ALREADY HAVE AN ACCOUNT ? &nbsp;
              <a
                onClick={() => {
                  localStorage.clear();
                  navigate("/user-login");
                }}
                style={{ cursor: "pointer", color: "white" }}
              >
                LOG IN
              </a>
            </p>
          </b>
        </div>
      </main>
      <Footer />
    </div>
  );
}
